package com.holudi.website.components.widgets

import CustomColors
import androidx.compose.runtime.Composable
import com.holudi.website.models.AppPortfolioItem
import com.holudi.website.theme.PortfolioCardOverlayStyle
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants.PORTFOLIO_CARD_WIDTH
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun PortfolioCard(
    modifier: Modifier = Modifier,
    appPortfolioItem: AppPortfolioItem,
) {

    Column(
        modifier = modifier
            .minWidth(260.px)
            .maxWidth(PORTFOLIO_CARD_WIDTH.px)
            .margin(20.px)
//            .border(2.px, LineStyle.Solid, LightGray)
            .borderRadius(topLeft = 20.px, topRight = 20.px, bottomLeft = 20.px, bottomRight = 20.px)
            .backgroundColor(appPortfolioItem.appColorLight)
    ) {
        Link(
            path = appPortfolioItem.link,
            modifier = PortfolioCardOverlayStyle.toModifier().textDecorationLine(TextDecorationLine.None)
        ) {
            Box(
                modifier = Modifier.id("overlayWrapper").fillMaxSize()
            ) {
                Column(
                    modifier = Modifier
                        .gap(20.px)
                        .padding(left = 30.px, right = 30.px, top = 30.px, bottom = 20.px)
                ) {
                    Image(
                        modifier = Modifier.maxSize(200.px).borderRadius(25.px).objectFit(ObjectFit.Cover),
                        src = appPortfolioItem.appIcon,
                        desc = appPortfolioItem.title
                    )
                    P(
                        attrs = Modifier
                            .margin(top = 10.px, bottom = 0.px)
                            .fillMaxWidth()
                            .fontSize(25.px)
                            .fontWeight(FontWeight.Bold)
                            .color(Theme.Colors.OnBackground.rgb)
                            .toAttrs()
                    ) {
                        Text(appPortfolioItem.title)
                    }
                    P(
                        attrs = Modifier
                            .minHeight(50.px)
                            .margin(bottom = 0.px)
                            .fontSize(16.px)
                            .fontWeight(FontWeight.Normal)
                            .color(Theme.Colors.OnBackground.rgb)
                            .toAttrs()
                    ) {
                        Text(appPortfolioItem.shortDescription)
                    }
                }
                ImageOverlay()
            }
        }
        Spacer()
        StoreBadges(appPortfolioItem, numColumns(base = 1, lg = 2))
    }
}


@Composable
private fun ImageOverlay() {
    Box(
        modifier = Modifier
            .id("imageOverlay")
            .fillMaxWidth()
            .borderRadius(topLeft = 20.px, topRight = 20.px, bottomLeft = 0.px, bottomRight = 0.px)
            .backgroundColor(CustomColors.Black50),
        contentAlignment = Alignment.Center
    ) {
        P(
            attrs = Modifier
                .id("learnMoreText")
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnPrimary.rgb)
                .toAttrs()
        ) {
            Text("Learn More")
        }

    }
}


