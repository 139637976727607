package com.holudi.website.models

object Routes {
    const val HOME = "/"
    const val SERVICES = "/services"
    const val CONTACT = "/contact"
    const val PRIVACY_POLICY = "/privacy-policy"
    const val TERMS = "/terms"
    const val IMPRINT = "/imprint"
    const val PORTFOLIO = "/portfolio"

//    const val APPS = "/apps"
    const val WELKIN_WEATHER = "/apps/welkin-weather"
    const val SORT_RELAX = "/apps/sort-relax"
}
