package com.holudi.website.models

import com.holudi.website.util.Res

val services = listOf(
    Service.AndroidNative,
    Service.Research,
    Service.UpdateUpgrade
)

enum class Service(
    val icon: String,
    val iconDesc: String,
    val caption: String,
    val title: String,
    val shortDescription: String,
    val description: String,
    val heroImage: String,
) {

    AndroidNative(
        Res.Icon.ANDROID,
        "Android Icon",
        "Android Development",
        "Full Service App Development",
        "Native Android Development with Kotlin and Jetpack Compose from requirements engineering to store releases.",
        "We offer Native Android Development with Kotlin and Jetpack Compose from requirements engineering to store releases. Utilizing close cooperation with clients during app creation workflow we ensure the resulting app meets expectation.",
        Res.Image.LAPTOP_WITH_TABLET
    ),

    Research(
        Res.Icon.FLASK,
        "Flask Icon",
        "Research",
        "Proof of Concept",
        "We can help bring ideas into reality. Feasibility studies and proof of concepts for a wide variety of hardware platforms.",
        "We can help bring ideas into reality. Feasibility studies and trials for a wide variety of hardware platforms, including VR headsets by utilizing Unity Engine.",
        Res.Image.QUEST
    ),

    UpdateUpgrade(
        Res.Icon.CYCLE,
        "Cycle Icon",
        "Update and Upgrade",
        "From Legacy to Supremacy",
        "Bringing legacy app code into the here and now by modernizing frameworks, architecture, UI and UX.",
        "Bringing legacy app code into the here and now by modernizing frameworks and architecture. Improving look and feel by reworking UI and UX based on the latest Material Design guidelines.",
        Res.Image.PHONE_ON_TABLE
    )
}
