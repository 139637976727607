package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.theme.Theme
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.px

@Composable
fun DecoLine() {
    Row(modifier = Modifier.gap(10.px).margin(bottom = 20.px)) {
        Box(
            modifier = Modifier
                .height(2.px)
                .width(80.px)
                .backgroundColor(Theme.Colors.Primary.rgb)
                .borderRadius(50.px)
        ) {

        }
        Box(
            modifier = Modifier
                .height(2.px)
                .width(30.px)
                .backgroundColor(Theme.Colors.Primary.rgb)
                .borderRadius(50.px)
        ) {

        }
        Box(
            modifier = Modifier
                .height(2.px)
                .width(10.px)
                .backgroundColor(Theme.Colors.Primary.rgb)
//                    .borderRadius(50.px)
        ) {

        }

    }
}