package com.holudi.website.components.sections

import androidx.compose.runtime.Composable
import com.holudi.website.components.widgets.PortfolioCard
import com.holudi.website.components.widgets.SectionTitle
import com.holudi.website.models.appPortfolioItems
import com.holudi.website.util.Constants
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun AppPortfolioSection() {

    Box(
        modifier = Modifier
//            .id("...") // anchor
            .maxWidth(Constants.MAX_WIDTH.px)
            .padding(topBottom = 100.px),
        contentAlignment = Alignment.Center
    ) {
        AppPortfolioContent()
    }

}

@Composable
fun AppPortfolioContent() {
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 90.percent
                else 80.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        SectionTitle(
            modifier = Modifier.fillMaxWidth().margin(bottom = 35.px),
            titleText = "Portfolio",
            subtitleText = "Mobile Apps",
            alignment = Alignment.CenterHorizontally
        )
        AppPortfolioCards()
    }
}

@Composable
fun AppPortfolioCards() {

    SimpleGrid(numColumns = numColumns(base = 1, sm = 1, md = 2)) {
        appPortfolioItems.forEach {
            PortfolioCard(modifier = Modifier, it)
        }
    }
}