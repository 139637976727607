package com.holudi.website.components.sections

import androidx.compose.runtime.Composable
import com.holudi.website.components.widgets.SocialRow
import com.holudi.website.models.NavigationItem
import com.holudi.website.models.footerAboutItems
import com.holudi.website.models.footerLegalItems
import com.holudi.website.theme.FooterLinkStyle
import com.holudi.website.theme.LogoStyle
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.holudi.website.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Divider
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun FooterSection() {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(Constants.MAX_WIDTH.px)
            .padding(top = 75.px, bottom = 30.px)
            .backgroundColor(Theme.Colors.BackgroundVariant.rgb),
        contentAlignment = Alignment.Center
    ) {
        FooterContent()
    }
}

@Composable
fun FooterContent() {
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally

    ) {

        if (breakpoint >= Breakpoint.MD) {
            Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween) {
                FooterLeft()
                Row {
                    FooterNavigationList("About", footerAboutItems)
                    FooterNavigationList("Legal", footerLegalItems)
                }
            }
        } else {
            Column(modifier = Modifier.fillMaxWidth().gap(25.px)) {
                FooterLeft()
                FooterNavigationList("About", footerAboutItems)
                FooterNavigationList("Legal", footerLegalItems)
            }
        }

        Divider(modifier = Modifier.fillMaxWidth().margin(top = 25.px, bottom = 15.px))
        Row(modifier = Modifier.fillMaxWidth()/*, horizontalArrangement = Arrangement.Center*/) {
            P(
                attrs = FooterLinkStyle.toModifier()
                    .margin(bottom = 15.px)
                    
                    .fontSize(14.px)
                    .fontWeight(FontWeight.Light)
                    .toAttrs()
            ) {
                Text("© Copyright ${Constants.LAST_UPDATED_YEAR} holudi e.U.")
            }
        }
    }
}

@Composable
fun FooterLeft() {
    Column(modifier = Modifier.fillMaxWidth()) {
        Link(
            path = "/",
        ) {
            Image(
                modifier = LogoStyle.toModifier().height(30.px),
                src = Res.Image.LOGO_LIGHT,
                desc = "Logo"
            )
        }
        P(
            attrs = Modifier
                .margin(top = 10.px)
                .fontSize(16.px)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text("Apps and Consulting")
        }
        SocialRow()
    }
}

@Composable
fun FooterNavigationList(title: String, entries: List<NavigationItem>) {

    Column(modifier = Modifier.width(200.px).gap(10.px).padding(bottom = 25.px), verticalArrangement = Arrangement.Top) {
        P(
            attrs = Modifier
                .margin(top = 0.px, bottom = 15.px)
                .fillMaxWidth()
                .fontSize(16.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text(title)
        }

        entries.forEach {
            Link(path = it.path, modifier = Modifier.textDecorationLine(TextDecorationLine.None)) {
                P(
                    attrs = FooterLinkStyle.toModifier()
                        .fontSize(16.px)
                        .fontWeight(FontWeight.Normal)
                        .toAttrs()
                ) {
                    Text(it.title)
                }
            }
        }
    }
}