package com.holudi.website.theme

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val NavigationItemStyle by ComponentStyle {
    base {
        Modifier.color(Theme.Colors.OnBackground.rgb)
            .transition(CSSTransition(property = "color", duration = 100.ms))
    }
    anyLink {
        Modifier.color(Theme.Colors.OnBackground.rgb)
    }
    hover {
        Modifier.color(Theme.Colors.Primary.rgb)
    }

}

@OptIn(ExperimentalComposeWebApi::class)
val LogoStyle by ComponentStyle {
    base {
        Modifier
            .transform { scale(1.0) }
//            .transform { rotate(0.deg) }
            .transition(CSSTransition(property = "transform", duration = 200.ms))
    }
    hover {
        Modifier
            .transform { scale(1.1) }
//            .transform { rotate((-10).deg) }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val SocialLinkStyle by ComponentStyle {
    base {
        Modifier
            .color(Theme.Colors.Primary.rgb)
            .transform { scale(1.0) }
//            .transform { rotate(0.deg) }
            .transition(CSSTransition(property = "transform", duration = 200.ms))
    }
    hover {
        Modifier
            .transform { scale(1.15) }
//            .transform { rotate((-10).deg) }
    }
}