package com.holudi.website.util

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.filter
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

object IconModifiers {
    val SM = Modifier.size(14.px)
    val L = Modifier.size(20.px)
    val XL = Modifier.size(24.px)
    val XXL = Modifier.size(32.px)
}

@OptIn(ExperimentalComposeWebApi::class)
val primaryColorModifier = Modifier.styleModifier {
    filter {
        invert(27.percent)
        sepia(29.percent)
        saturate(3727.percent)
        hueRotate(264.deg)
        brightness(89.percent)
        contrast(101.percent)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val whiteColorModifier = Modifier.styleModifier {
    filter {
        invert(100.percent)
        sepia(0.percent)
        saturate(7500.percent)
        hueRotate(62.deg)
        brightness(103.percent)
        contrast(101.percent)
    }
}