package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.theme.GreyscaleImageStyle
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.px

@Composable
fun HeroImage(src: String, maxWidth: CSSNumeric = 700.px) {

    Column(
        modifier = Modifier.fillMaxWidth().maxWidth(maxWidth)
    ) {
        Image(
            modifier = Modifier.fillMaxSize(),
            src = src,
            desc = "Image"
        )
    }
}

@Composable
fun GrayscaleHeroImage(src: String, maxWidth: CSSNumeric = 700.px) {

    Column(
        modifier = Modifier.fillMaxWidth().maxWidth(maxWidth)
    ) {
        Image(
            modifier = GreyscaleImageStyle.toModifier().fillMaxSize(),
            src = src,
            desc = "Image"
        )
    }
}
