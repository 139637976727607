package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.util.Res
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.px

@Composable
fun PlayStoreBadge(link: String) {
    Link(
        path = link,
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {
        Image(
            modifier = Modifier.height(60.px),
            src = Res.Image.PLAY_STORE_BADGE,
        )
    }
}
