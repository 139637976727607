package com.holudi.website.pages

import androidx.compose.runtime.Composable
import com.holudi.website.components.layouts.GenericPageLayout
import com.holudi.website.components.sections.CompanyHeroSection
import com.holudi.website.components.widgets.HeroImage
import com.holudi.website.components.widgets.HeroSectionTitle
import com.holudi.website.models.NavigationItem
import com.holudi.website.models.Service
import com.holudi.website.models.services
import com.holudi.website.theme.Theme
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun Services() {
    val breakpoint = rememberBreakpoint()
    Column(modifier = Modifier.fillMaxSize()) {
        GenericPageLayout("Services", content = {
            ServicesContent(breakpoint)
        }, fullWidthContent = {
            CompanyHeroSection(breakpoint, NavigationItem.Portfolio)
        })
    }
}

@Composable
fun ServicesContent(breakpoint: Breakpoint) {

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        ServicesSection(breakpoint)
    }
}


@Composable
private fun ServicesSection(breakpoint: Breakpoint) {
    services.forEachIndexed { index, service ->
        ServiceSectionItem(breakpoint, index.mod(2) != 0, service)
    }
}

@Composable
private fun ServiceSectionItem(breakpoint: Breakpoint, reverse: Boolean, service: Service) {
    Column(
        modifier = Modifier.fillMaxWidth().margin(bottom = 30.px),
        verticalArrangement = Arrangement.Center
    ) {

        if (breakpoint >= Breakpoint.MD) {
            Row(
                modifier = Modifier.fillMaxWidth().gap(50.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (reverse) {
                    ServiceSectionText(breakpoint, service)
                    HeroImage(service.heroImage, 500.px)
                } else {
                    HeroImage(service.heroImage, 500.px)
                    ServiceSectionText(breakpoint, service)
                }

            }
        } else {
            Column(modifier = Modifier.fillMaxWidth().gap(25.px)) {
                ServiceSectionText(breakpoint, service)
                HeroImage(service.heroImage, 500.px)
            }
        }
    }
}

@Composable
private fun ServiceSectionText(breakpoint: Breakpoint, service: Service) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(topBottom = 30.px)
    ) {
        HeroSectionTitle(titleText = service.caption, subtitleText = service.title)
        P(
            attrs = Modifier
                .maxWidth(500.px)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text(service.description)
        }
    }
}
