package com.holudi.website.models

import com.holudi.website.util.Res

val webDevelopmentItems = listOf(
    WebDevelopment.HoludiWebsite,
    WebDevelopment.WelkinWeatherBackend
)

enum class WebDevelopment(
    val caption: String,
    val title: String,
    val description: String,
    val externalLink: String,
    val heroImage: String,
) {
    HoludiWebsite(
        "Frontend",
        "holudi Website",
        "This website was built with kobweb.",
        "https://kobweb.varabyte.com/",
        Res.Image.WEBSITE
    ),
    WelkinWeatherBackend(
        "Backend",
        "Welkin Weather Backend",
        "The backend for Welkin Weather app was built with ktor.",
        "https://ktor.io/",
        Res.Image.WELKIN_WEATHER_FEATURE_GRAPHIC
    ),
}
