package com.holudi.website.pages.apps

import androidx.compose.runtime.Composable
import com.holudi.website.components.layouts.GenericPageLayout
import com.holudi.website.components.sections.AppMainSection
import com.holudi.website.models.AppPortfolioItem
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Iframe

@Page(routeOverride = "sort-relax")
@Composable
fun SortRelax() {
    val breakpoint = rememberBreakpoint()
    GenericPageLayout("Sort & Relax", content = {
        Column(modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
            AppMainSection(breakpoint, AppPortfolioItem.SortRelax)

            Box(
                modifier = Modifier.fillMaxSize()
                    .maxWidth(750.px)
                    .aspectRatio(width = 16, height = 9)
                    .margin(top = 100.px)
            ) {

                Iframe(
                    attrs = Modifier.fillMaxSize().toAttrs {
                        ref { htmlIframeElement ->
                            htmlIframeElement.src = "https://www.youtube.com/embed/U7qLbLxNFpk"
                            onDispose { }
                        }
                    }) {
                }
            }
        }
    })
}
