package com.holudi.website.components.widgets

import CustomColors
import androidx.compose.runtime.Composable
import com.holudi.website.models.Service
import com.holudi.website.theme.ServiceCardStyle
import com.holudi.website.theme.ServiceIconStyle
import com.holudi.website.theme.Theme
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun ServiceCard(service: Service) {
    Column(
        modifier = ServiceCardStyle.toModifier()
            .maxWidth(300.px)
            .margin(20.px)
            .padding(20.px)
            .border(2.px, LineStyle.Solid, CustomColors.LightGray)
            .borderRadius(topLeft = 20.px, topRight = 20.px, bottomLeft = 20.px, bottomRight = 0.px)
    ) {

        Box(
            modifier = Modifier
                .id("iconBox")
                .padding(10.px)
                .margin(bottom = 20.px)
                .border(2.px, LineStyle.Solid, Theme.Colors.Primary.rgb)
                .borderRadius(topLeft = 15.px, topRight = 15.px, bottomLeft = 15.px, bottomRight = 0.px)
        ) {
            Image(modifier = ServiceIconStyle.toModifier().size(20.px), src = service.icon, desc = service.iconDesc)
        }

        P(
            attrs = Modifier
                .margin(top = 0.px, bottom = 20.px)
                .fillMaxWidth()
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .toAttrs()
        ) {
            Text(service.caption)
        }

        P(
            attrs = Modifier
                .margin(top = 0.px, bottom = 0.px)
                .maxWidth(400.px)
                .fontSize(14.px)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text(service.shortDescription)
        }
    }

}