package com.holudi.website.components.sections

import androidx.compose.runtime.Composable
import com.holudi.website.components.widgets.FilledButton
import com.holudi.website.components.widgets.HoludiNavBar
import com.holudi.website.components.widgets.OutlinedButton
import com.holudi.website.models.NavigationItem
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.holudi.website.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Composable
fun MainSection(onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    Box(
        modifier = Modifier
//            .id(NavigationItem.Home.id) // site anchor, not needed for now
            .maxWidth(Constants.MAX_WIDTH.px),
        contentAlignment = Alignment.TopCenter
    ) {
        MainBackground()
        MainContent(breakpoint, onMenuClicked)
    }
}

@Composable
fun MainBackground() {
    Image(
        modifier = Modifier.fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = Res.Image.HOME_BACKGROUND,
        desc = "Background"
    )
}

@Composable
fun MainContent(breakpoint: Breakpoint, onMenuClicked: () -> Unit) {
    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        HoludiNavBar(onDarkBackground = true, showContactButton = true, onMenuClicked = onMenuClicked)

        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            if (breakpoint >= Breakpoint.LG) {

                Row(
                    modifier = Modifier.fillMaxWidth(
                        if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
                    ),
                    verticalAlignment = Alignment.Bottom,
                    horizontalArrangement = Arrangement.Center
                ) {
                    MainText(breakpoint)
                    MainImage()
                }

            } else {
                Column(
                    modifier = Modifier.fillMaxWidth(
                        if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
                    ),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    MainText(breakpoint)
                    MainImage()
                }
            }

//            SimpleGrid(
//                modifier = Modifier.fillMaxWidth(
//                    if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
//                ),
//                numColumns = numColumns(base = 1, md = 2)
//            ) {
//                MainText(breakpoint)
//                MainImage()
//            }
        }
    }
}


@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun MainText(breakpoint: Breakpoint) {


    Column(
        modifier = Modifier.fillMaxHeight().padding(topBottom = 30.px),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {

        Column {
            H1(
                attrs = Modifier
                    .margin(top = 20.px, bottom = 0.px)
                    .maxWidth(550.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Colors.OnBackground.rgb)
                    .toAttrs()
            ) {
//                Text("Empowering your Business with Cutting-Edge Technology and Expert Consultation")
                Text("Innovative App Solutions for Your Business")
            }

            P(
                attrs = Modifier
                    .margin(top = 20.px, bottom = 0.px)
                    .maxWidth(550.px)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Colors.OnBackground.rgb)
                    .toAttrs()
            ) {
//                Text("At holudi, we bring your digital aspirations to life with unparalleled expertise in app development and IT consulting. Our tailor-made solutions not only solve your unique challenges but also offer a competitive edge in a saturated market. Trust us to drive innovation while maximising efficiency and profitability for your business")
                Text("At holudi, we turn your digital dreams into reality with our expertise in app development and IT consulting. Our tailored solutions address your unique challenges and give your business a competitive edge. Let us lead the way in innovation, effectiveness, and quality.")
            }

            Row(modifier = Modifier.gap(20.px).margin(topBottom = 30.px)) {
                with(NavigationItem.Services) {
                    FilledButton(path = path, text = longTitle)
                }
                with(NavigationItem.Portfolio) {
                    OutlinedButton(path = path, text = longTitle)
                }
            }
        }
    }
}


//@OptIn(ExperimentalComposeWebApi::class)
//@Composable
//fun MainText(breakpoint: Breakpoint) {
//
//
//    Column(
//        modifier = Modifier.padding(topBottom = 30.px),
//        horizontalAlignment = Alignment.CenterHorizontally,
//        verticalArrangement = Arrangement.Center
//    ) {
//
//        Column {
//
//            Image(
//                modifier = LogoStyle.toModifier().height(if (breakpoint >= Breakpoint.LG) 50.px else 40.px),
//                src = Res.Image.LOGO_LIGHT,
//                desc = "Logo"
//            )
//            P(
//                attrs = Modifier
//                    .margin(top = 20.px, bottom = 0.px)
//                    .maxWidth(400.px)
//                    .fontSize(18.px)
//                    .fontWeight(FontWeight.Normal)
//                    .color(Theme.Colors.OnBackground.rgb)
//                    .toAttrs()
//            ) {
//                Text("Apps and Consulting")
//            }
//
//            Row(modifier = Modifier.gap(20.px).margin(topBottom = 30.px)) {
//                with(NavigationItem.Portfolio) {
//                    FilledButton(path = path, text = title)
//                }
//
//                with(NavigationItem.Services) {
//                    OutlinedButton(path = path, text = title)
//                }
//            }
//        }
//    }
//}


@Composable
fun MainImage() {

    Column(
        modifier = Modifier.fillMaxHeight(),
        verticalArrangement = Arrangement.Bottom
    ) {

        Box(
            modifier = Modifier.fillMaxSize()/*.maxWidth(800.px)*/,
            contentAlignment = Alignment.BottomCenter
        ) {

            Image(
                modifier = Modifier
                    .fillMaxSize()
                    .objectFit(ObjectFit.Contain),
                src = Res.Image.PHONES_APP_SCREENS,
                desc = "Main Image"
            )
        }
    }
}
