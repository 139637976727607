package com.holudi.website.pages

import androidx.compose.runtime.Composable
import com.holudi.website.components.layouts.GenericPageLayout
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun Imnrint() {
    val breakpoint = rememberBreakpoint()
    GenericPageLayout("Imprint", content = {
        ImprintContent()
    })
}

@Composable
fun ImprintContent() {

    Column(modifier = Modifier.fillMaxWidth()) {
        P(
            attrs = Modifier
                .margin(top = 25.px, bottom = 30.px)
                .fillMaxWidth()
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("holudi e.U.")
        }

        ImprintParagraph(listOf("Owner: DI(FH) Birgit Miriam Lexa"))

        P(
            attrs = Modifier
                .margin(bottom = 20.px)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("E-Mail: ")
            Link(
                modifier = Modifier.color(Theme.Colors.Primary.rgb),
                text = "info@holudi.com", path = "mailto:info@holudi.com"
            )
            Br()
            Text("Web: ")
            Link(
                modifier = Modifier.color(Theme.Colors.Primary.rgb),
                text = "https://www.holudi.com", path = "https://www.holudi.com"
            )
        }
        ImprintParagraph(listOf("Location: Graz, Austria"))
        ImprintParagraph(
            listOf(
                "Company registration number: FN 581221 m",
                "VAT number: ATU78172479"
            )
        )

    }
}

@Composable
fun ImprintParagraph(textLines: List<String>) {
    P(
        attrs = Modifier
            .margin(bottom = 20.px)
            .fontSize(18.px)
            .fontWeight(FontWeight.Normal)
            .color(Theme.Colors.OnBackground.rgb)
            .toAttrs()
    ) {
        textLines.forEachIndexed { index, line ->
            if (index > 0) {
                Br()
            }
            Text(line)
        }
    }
}
