import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.holudi.website.pages.HomePage() }
        ctx.router.register("/apps/sort-relax") { com.holudi.website.pages.apps.SortRelax() }
        ctx.router.register("/apps/welkin-weather") { com.holudi.website.pages.apps.WelkinWeather()
                }
        ctx.router.register("/contact") { com.holudi.website.pages.Contact() }
        ctx.router.register("/imprint") { com.holudi.website.pages.Imnrint() }
        ctx.router.register("/portfolio") { com.holudi.website.pages.Portfolio() }
        ctx.router.register("/privacy-policy") { com.holudi.website.pages.PrivacyPolicy() }
        ctx.router.register("/services") { com.holudi.website.pages.Services() }
        ctx.router.register("/terms") { com.holudi.website.pages.Terms() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.FilledButtonStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.FilledSecondaryButtonStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.OutlinedButtonStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.GreyscaleImageStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.FooterLinkStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.NavigationItemStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.LogoStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.SocialLinkStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.PortfolioCardOverlayStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.ServiceCardStyle)
        ctx.theme.registerComponentStyle(com.holudi.website.theme.ServiceIconStyle)
        com.holudi.website.updateTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(AppGlobalsLocal provides mapOf("title" to
                "holudi - Apps and Consulting")) {
            com.holudi.website.MyApp {
                router.renderActivePage { renderWithDeferred { it() } }
            }
        }
    }
}
