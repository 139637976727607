package com.holudi.website.models

enum class NavigationItem(val id: String, val title: String, val longTitle: String, val path: String) {
    Home(id = "home", title = "Home", longTitle = "", path = Routes.HOME),
    Apps(id = "apps", title = "Apps", longTitle = "", path = ""),
    Portfolio(id = "portfolio", title = "Portfolio", longTitle = "Visit Portfolio", path = Routes.PORTFOLIO),
    WelkinWeather(id = "welkin", title = "Welkin Weather", longTitle = "", path = Routes.WELKIN_WEATHER),
    SortRelax(id = "sort", title = "Sort & Relax", longTitle = "", path = Routes.SORT_RELAX),
    Services(id = "services", title = "Services", longTitle = "Our Services", path = Routes.SERVICES),
    Contact(id = "contact", title = "Contact", longTitle = "Get in touch", path = Routes.CONTACT),
    PrivacyPolicy(id = "privacy", title = "Privacy Policy", longTitle = "", path = Routes.PRIVACY_POLICY),
    Terms(id = "terms", title = "Terms and Conditions", longTitle = "", path = Routes.TERMS),
    Imprint(id = "imprint", title = "Imprint", longTitle = "", path = Routes.IMPRINT),
}

val navbarNavigationItems = listOf(
    NavigationItem.Apps to listOf(NavigationItem.WelkinWeather, NavigationItem.SortRelax),
    NavigationItem.Services to emptyList(),
    NavigationItem.Portfolio to emptyList()
)
val footerAboutItems = listOf(NavigationItem.Portfolio, NavigationItem.Services, NavigationItem.Contact)
val footerLegalItems = listOf(NavigationItem.PrivacyPolicy, NavigationItem.Terms, NavigationItem.Imprint)
