package com.holudi.website.pages

import androidx.compose.runtime.Composable
import com.holudi.website.components.layouts.GenericPageLayout
import com.holudi.website.components.widgets.FilledIconButton
import com.holudi.website.components.widgets.SocialRow
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.mdi.MdiMail
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun Contact() {
    GenericPageLayout("Contact us", content = {
        ContactContent()
    })
}

@Composable
fun ContactContent() {

    Column(modifier = Modifier.fillMaxWidth()) {
        P(
            attrs = Modifier
                .margin(top = 0.px, bottom = 20.px)
                .fillMaxWidth()
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("App support")
        }
        P(
            attrs = Modifier
                .margin(bottom = 25.px)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("Get help with app related issues or report bugs.")
        }
        FilledIconButton("mailto:support@holudi.com", "support@holudi.com") {
            MdiMail()
        }
        P(
            attrs = Modifier
                .margin(top = 50.px, bottom = 25.px)
                .fillMaxWidth()
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("Contract work")
        }
        P(
            attrs = Modifier
                .margin(bottom = 25.px)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("Write us an email and let’s start talking about getting your apps up and running.")
        }

        FilledIconButton("mailto:info@holudi.com", "info@holudi.com") {
            MdiMail()
        }

        P(
            attrs = Modifier
                .margin(top = 50.px, bottom = 25.px)
                .fillMaxWidth()
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("Follow us on Social Media")
        }

        SocialRow()
    }
}