package com.holudi.website.components.widgets

import CustomColors
import androidx.compose.runtime.*
import com.holudi.website.models.NavigationItem
import com.holudi.website.models.Routes
import com.holudi.website.models.navbarNavigationItems
import com.holudi.website.util.Res
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*

@Composable
fun OverflowMenu(onMenuClosed: () -> Unit) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var translateX by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(breakpoint) {
        translateX = 0.percent
        opacity = 100.percent
        if (breakpoint > Breakpoint.MD) {
            scope.launch {
                translateX = (-100).percent
                opacity = 0.percent
                delay(500)
                onMenuClosed()
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(1) // show on top of regular (z=0) layouts
            .opacity(opacity)
            .backgroundColor(CustomColors.Black50)
            .transition(CSSTransition(property = "opacity", duration = 500.ms))
    ) {

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .padding(40.px)
                .gap(30.px)
                .width(300.px/*if (breakpoint < Breakpoint.MD) 50.percent else 25.percent*/)
                .backgroundColor(Colors.White)
                .translateX(tx = translateX)
                .transition(CSSTransition(property = "translate", duration = 500.ms))
        ) {
            Row(
                modifier = Modifier.margin(bottom = 20.px).gap(30.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaXmark(
                    modifier = Modifier.cursor(Cursor.Pointer)
                        .onClick {
                            scope.launch {
                                translateX = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onMenuClosed()
                            }
                        },
                    size = IconSize.XL
                )
                Image(
                    modifier = Modifier.height(25.px),
                    src = Res.Image.LOGO_LIGHT,
                    desc = "Logo"
                )

            }
            navbarNavigationItems.forEach { item ->
                if (item.second.isNotEmpty()) {
                    DropDownParentMenuItem(item.first)
                    item.second.forEach {
                        Row {
                            Box(modifier = Modifier.width(25.px)) { }
                            MenuItem(it)
                        }
                    }
                } else {
                    MenuItem(item.first)
                }
            }
            with(NavigationItem.Contact) {
                FilledSecondaryButton(path, longTitle)
            }
        }
    }
}
