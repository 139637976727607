package com.holudi.website.pages

import androidx.compose.runtime.Composable
import com.holudi.website.components.layouts.GenericPageLayout
import com.holudi.website.components.sections.AppPortfolioCards
import com.holudi.website.components.sections.CompanyHeroSection
import com.holudi.website.components.widgets.DecoLine
import com.holudi.website.components.widgets.HeroImage
import com.holudi.website.components.widgets.SectionTitle
import com.holudi.website.models.NavigationItem
import com.holudi.website.models.WebDevelopment
import com.holudi.website.models.webDevelopmentItems
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.holudi.website.util.Res
import com.holudi.website.util.primaryColorModifier
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Divider
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun Portfolio() {
    val breakpoint = rememberBreakpoint()
    Column(modifier = Modifier.fillMaxSize()) {
        GenericPageLayout("Portfolio", content = {
            PortfolioContent(breakpoint)
        }, fullWidthContent = {
            CompanyHeroSection(breakpoint, NavigationItem.Services)
        })
    }
}

@Composable
fun PortfolioContent(breakpoint: Breakpoint) {

    Column(
        modifier = Modifier.fillMaxWidth(),
//        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        AppPortfolioSection(breakpoint)
        WebPortfolioSection(breakpoint)
    }
}

@Composable
private fun SectionHeader(text: String) {
    Column(modifier = Modifier.fillMaxWidth().margin(bottom = 25.px)) {
        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(top = 5.px, bottom = 10.px)
                .fontSize(36.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .whiteSpace(WhiteSpace.NoWrap)
                .toAttrs()
        ) {
            Text(text)
        }
        DecoLine()
    }
}

@Composable
private fun AppPortfolioSection(breakpoint: Breakpoint) {

    Box(
        modifier = Modifier
//            .id("...") // anchor
            .maxWidth(Constants.MAX_WIDTH.px)
            .padding(bottom = 100.px),
        contentAlignment = Alignment.Center
    ) {
        AppPortfolioContent(breakpoint)
    }
}

@Composable
private fun AppPortfolioContent(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 90.percent
                else 80.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        Column(
            modifier = Modifier.fillMaxWidth(

            )
        ) {
            SectionHeader("Mobile Apps")
            AppPortfolioCards()
        }
    }
}

@Composable
private fun WebPortfolioSection(breakpoint: Breakpoint) {

    Box(
        modifier = Modifier
//            .id("...") // anchor
            .maxWidth(Constants.MAX_WIDTH.px)
            .padding(topBottom = 70.px),
        contentAlignment = Alignment.Center
    ) {
        WebPortfolioContent(breakpoint)
    }

}

@Composable
private fun WebPortfolioContent(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 90.percent
                else 80.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        Column(
            modifier = Modifier.fillMaxWidth(

            )
        ) {
            SectionHeader("Web Development")
            webDevelopmentItems.forEachIndexed { index, webDevelopment ->
                WebSectionItem(breakpoint, index.mod(2) != 0, webDevelopment)

                if (index < webDevelopmentItems.lastIndex)
                    Divider(modifier = Modifier.fillMaxWidth().margin(top = 75.px, bottom = 75.px))
            }
        }
    }
}

@Composable
private fun WebSectionItem(breakpoint: Breakpoint, reverse: Boolean, webDevelopment: WebDevelopment) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.Center
    ) {

        if (breakpoint >= Breakpoint.MD) {
            Row(
                modifier = Modifier.fillMaxWidth().gap(50.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (reverse) {
                    WebSectionText(breakpoint, webDevelopment)
                    HeroImage(webDevelopment.heroImage, 500.px)
                } else {
                    HeroImage(webDevelopment.heroImage, 500.px)
                    WebSectionText(breakpoint, webDevelopment)
                }
            }
        } else {
            Column(modifier = Modifier.fillMaxWidth().gap(25.px)) {
                WebSectionText(breakpoint, webDevelopment)
                HeroImage(webDevelopment.heroImage, 500.px)
            }
        }
    }
}

@Composable
private fun WebSectionText(breakpoint: Breakpoint, webDevelopmentItem: WebDevelopment) {
    Column(modifier = Modifier.fillMaxWidth()) {
        SectionTitle(titleText = webDevelopmentItem.caption, subtitleText = webDevelopmentItem.title)
        P(
            attrs = Modifier
                .maxWidth(500.px)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text(webDevelopmentItem.description)
        }
        Link(
            modifier = Modifier
                .color(Theme.Colors.Primary.rgb)
                .margin(top = 20.px)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .textDecorationLine(TextDecorationLine.None),
            path = webDevelopmentItem.externalLink,
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ) {
            Row(modifier = Modifier.gap(10.px), verticalAlignment = Alignment.CenterVertically) {
                Image(
                    modifier = primaryColorModifier.size(20.px),
                    src = Res.Icon.OPEN_IN_NEW
                )
                Text(webDevelopmentItem.externalLink)
            }
        }
    }
}

