package com.holudi.website.components.layouts

import androidx.compose.runtime.*
import com.holudi.website.components.sections.FooterSection
import com.holudi.website.components.widgets.DecoLine
import com.holudi.website.components.widgets.HoludiNavBar
import com.holudi.website.components.widgets.OverflowMenu
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun GenericPageLayout(
    title: String,
    content: @Composable () -> Unit,
    fullWidthContent: @Composable () -> Unit = {}
) {
    var menuOpened by remember { mutableStateOf(false) }
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        HoludiNavBar(onMenuClicked = {
            menuOpened = true
        })


        Box(
            modifier = Modifier
//            .id("...") // anchor
                .maxWidth(Constants.MAX_WIDTH.px)
                .padding(top = 25.px, bottom = 50.px),
            contentAlignment = Alignment.Center
        ) {


            Column(
                modifier = Modifier
                    .fillMaxWidth(
                        if (breakpoint >= Breakpoint.MD) 90.percent
                        else 80.percent
                    ),
                horizontalAlignment = Alignment.CenterHorizontally

            ) {
                P(
                    attrs = Modifier
                        .fillMaxWidth()
                        .textAlign(TextAlign.Center)
                        .margin(top = 0.px, bottom = 10.px)
                        .fontSize(40.px)
                        .fontWeight(FontWeight.Bold)
                        .color(Theme.Colors.OnBackground.rgb)
                        .toAttrs()
                ) {
                    Text(title)
                }
                DecoLine()
                Box(modifier = Modifier.margin(top = 50.px, bottom = 50.px)) {
                    content()
                }
            }
        }
        fullWidthContent()
        FooterSection()
    }


    if (menuOpened) {
        OverflowMenu { menuOpened = false }
    }
}
