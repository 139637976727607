package com.holudi.website.components.sections

import androidx.compose.runtime.Composable
import com.holudi.website.components.widgets.FilledButton
import com.holudi.website.components.widgets.OutlinedButton
import com.holudi.website.components.widgets.SectionTitle
import com.holudi.website.components.widgets.ServiceCard
import com.holudi.website.models.NavigationItem
import com.holudi.website.models.Routes
import com.holudi.website.models.services
import com.holudi.website.util.Constants
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun ServicesOverviewSection() {

    Box(
        modifier = Modifier
//            .id("...") // anchor
            .maxWidth(Constants.MAX_WIDTH.px)
            .padding(top = 50.px, bottom = 100.px),
        contentAlignment = Alignment.Center
    ) {
        ServiceContent()
    }

}

@Composable
private fun ServiceContent() {
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 90.percent
                else 80.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        SectionTitle(
            modifier = Modifier.fillMaxWidth().margin(bottom = 35.px),
            titleText = "Services",
            subtitleText = "What we offer",
            alignment = Alignment.CenterHorizontally
        )

        SimpleGrid(numColumns = numColumns(base = 1, sm = 2, lg = 3)) {
            services.forEach {
                ServiceCard(it)
            }
        }
        Row(modifier = Modifier.margin(top = 30.px).gap(20.px)) {
            with(NavigationItem.Contact) {
                FilledButton(path = path, text = longTitle)
            }

            OutlinedButton(path = Routes.SERVICES, "Learn More")

        }
    }
}

