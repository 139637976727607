package com.holudi.website.models

import CustomColors
import com.holudi.website.util.Res
import org.jetbrains.compose.web.css.CSSColorValue

val appPortfolioItems = listOf(AppPortfolioItem.SortRelax, AppPortfolioItem.WelkinWeather)

enum class AppPortfolioItem(
    val appIcon: String,
    val appScreenshot: String,
    val appColorLight: CSSColorValue,
    val title: String,
    val shortDescription: String,
    val description: String,
    val link: String,
    val playStoreLink: String?,
    val appStoreLink: String?
) {
    SortRelax(
        Res.Image.SORT_RELAX,
        Res.Image.SORT_RELAX_SCREENSHOT_BEZEL,
        CustomColors.PurpleLight,
        "Sort & Relax",
        "Casual sorting game built with Unity for Android and iOS",
        "Find your inner peace through the power of sorting with Sort & Relax, the relaxing and enjoyable mobile sorting game. You'll be challenged to sort a variety of objects by color at your own pace. With simple gameplay and calming graphics, this game is the perfect way to de-stress and improve your problem-solving skills.",
        Routes.SORT_RELAX,
        "https://play.google.com/store/apps/details?id=com.holudi.sortitall",
        "https://apps.apple.com/us/app/sort-relax/id6464299054"
    ),
    WelkinWeather(
        Res.Image.WELKIN_WEATHER,
        Res.Image.WELKIN_WEATHER_SCREENSHOT_BEZEL,
        CustomColors.WelkinYellowLight,
        "Welkin Weather",
        "Weather app for Android showing all relevant weather information at a glance",
        "Welkin Weather: Your All-in-One Weather Companion!\n\n" +
                "Welkin Weather is the ultimate weather app designed to provide you with the most important weather information at a glance. With Welkin Weather, everything you need to know about the weather is right in front of you.",
        Routes.WELKIN_WEATHER,
        "https://play.google.com/store/apps/details?id=com.holudi.weather",
        null
    )
}
