package com.holudi.website.theme

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val FooterLinkStyle by ComponentStyle {
    base {
        Modifier
            .color(Theme.Colors.OnBackground.rgb)
            .transition(CSSTransition(property = "color", duration = 100.ms))
    }

    hover {
        Modifier
            .color(Theme.Colors.Primary.rgb)
    }

}
