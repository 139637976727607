import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import org.jetbrains.compose.web.css.rgb

object CustomColors {
    val Purple = rgb(0x89, 0x2c, 0xa0) // 892ca0
    val PurpleLight = rgb(0xd6, 0x9b, 0xe3) // d69be3
    val WelkinYellowLight = rgb(0xfc, 0xf1, 0xc0) // fcf1c0
    val DarkGray = rgb(26, 26, 26)
    val LightGray = rgb(229, 229, 229)
    val Yellow = rgb(0xff, 0xd8, 0x63)
    val YellowLight = rgb(0xfd, 0xe8, 0xad) //fde8ad

    // with transparency
    val Purple50 = argb(.5f, 0x89, 0x2c, 0xa0)
    val Purple25 = argb(.25f, 0x89, 0x2c, 0xa0)
    val Purple10 = argb(.1f, 0x89, 0x2c, 0xa0)
    val Black50 = argb(0.5f, 0x00, 0x00, 0x00)
}


//val md_theme_light_primary = Color(0xFF9134A7)
//val md_theme_light_onPrimary = Color(0xFFFFFFFF)
//val md_theme_light_primaryContainer = Color(0xFFFDD6FF)
//val md_theme_light_onPrimaryContainer = Color(0xFF340042)
//val md_theme_light_secondary = Color(0xFF006B5F)
//val md_theme_light_onSecondary = Color(0xFFFFFFFF)
//val md_theme_light_secondaryContainer = Color(0xFF74F8E4)
//val md_theme_light_onSecondaryContainer = Color(0xFF00201C)
//val md_theme_light_tertiary = Color(0xFF006874)
//val md_theme_light_onTertiary = Color(0xFFFFFFFF)
//val md_theme_light_tertiaryContainer = Color(0xFF97F0FF)
//val md_theme_light_onTertiaryContainer = Color(0xFF001F24)
//val md_theme_light_error = Color(0xFFBA1A1A)
//val md_theme_light_errorContainer = Color(0xFFFFDAD6)
//val md_theme_light_onError = Color(0xFFFFFFFF)
//val md_theme_light_onErrorContainer = Color(0xFF410002)
//val md_theme_light_background = Color(0xFFF8FDFF)
//val md_theme_light_onBackground = Color(0xFF001F25)
//val md_theme_light_surface = Color(0xFFF8FDFF)
//val md_theme_light_onSurface = Color(0xFF001F25)
//val md_theme_light_surfaceVariant = Color(0xFFECDFE9)
//val md_theme_light_onSurfaceVariant = Color(0xFF4D444C)
//val md_theme_light_outline = Color(0xFF7E747D)
//val md_theme_light_inverseOnSurface = Color(0xFFD6F6FF)
//val md_theme_light_inverseSurface = Color(0xFF00363F)
//val md_theme_light_inversePrimary = Color(0xFFF4AEFF)
//val md_theme_light_shadow = Color(0xFF000000)
//val md_theme_light_surfaceTint = Color(0xFF9134A7)
//val md_theme_light_outlineVariant = Color(0xFFCFC3CD)
//val md_theme_light_scrim = Color(0xFF000000)
//
//val md_theme_dark_primary = Color(0xFFF4AEFF)
//val md_theme_dark_onPrimary = Color(0xFF55006A)
//val md_theme_dark_primaryContainer = Color(0xFF75148D)
//val md_theme_dark_onPrimaryContainer = Color(0xFFFDD6FF)
//val md_theme_dark_secondary = Color(0xFF54DBC8)
//val md_theme_dark_onSecondary = Color(0xFF003731)
//val md_theme_dark_secondaryContainer = Color(0xFF005048)
//val md_theme_dark_onSecondaryContainer = Color(0xFF74F8E4)
//val md_theme_dark_tertiary = Color(0xFF4FD8EB)
//val md_theme_dark_onTertiary = Color(0xFF00363D)
//val md_theme_dark_tertiaryContainer = Color(0xFF004F58)
//val md_theme_dark_onTertiaryContainer = Color(0xFF97F0FF)
//val md_theme_dark_error = Color(0xFFFFB4AB)
//val md_theme_dark_errorContainer = Color(0xFF93000A)
//val md_theme_dark_onError = Color(0xFF690005)
//val md_theme_dark_onErrorContainer = Color(0xFFFFDAD6)
//val md_theme_dark_background = Color(0xFF001F25)
//val md_theme_dark_onBackground = Color(0xFFA6EEFF)
//val md_theme_dark_surface = Color(0xFF001F25)
//val md_theme_dark_onSurface = Color(0xFFA6EEFF)
//val md_theme_dark_surfaceVariant = Color(0xFF4D444C)
//val md_theme_dark_onSurfaceVariant = Color(0xFFCFC3CD)
//val md_theme_dark_outline = Color(0xFF988D97)
//val md_theme_dark_inverseOnSurface = Color(0xFF001F25)
//val md_theme_dark_inverseSurface = Color(0xFFA6EEFF)
//val md_theme_dark_inversePrimary = Color(0xFF9134A7)
//val md_theme_dark_shadow = Color(0xFF000000)
//val md_theme_dark_surfaceTint = Color(0xFFF4AEFF)
//val md_theme_dark_outlineVariant = Color(0xFF4D444C)
//val md_theme_dark_scrim = Color(0xFF000000)
//
//
//val seed = Color(0xFF892CA0)