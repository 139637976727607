package com.holudi.website.theme

import CustomColors
import com.varabyte.kobweb.compose.ui.graphics.Colors.White
import org.jetbrains.compose.web.css.CSSColorValue

object Theme {
    enum class Colors(val rgb: CSSColorValue) {
        Primary(CustomColors.Purple),
        Secondary(CustomColors.Yellow),
        OnBackground(CustomColors.DarkGray),
        OnPrimary(White),
        Background(White),
        BackgroundVariant(CustomColors.LightGray)
    }
}
