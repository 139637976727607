package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.theme.SocialLinkStyle
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaFacebook
import com.varabyte.kobweb.silk.components.icons.fa.FaInstagram
import com.varabyte.kobweb.silk.components.icons.fa.FaTwitter
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px

/**
 * With white rounded background
 */
@Composable
fun SocialBar() {
    Row(
        modifier = Modifier
            .margin(topBottom = 25.px)
            .padding(leftRight = 25.px)
            .gap(35.px)
            .minHeight(40.px)
            .borderRadius(20.px)
            .backgroundColor(Colors.White),
        verticalAlignment = Alignment.CenterVertically
//        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        SocialLinks()

    }

}

/**
 * No background
 */
@Composable
fun SocialRow() {
    Row(
        modifier = Modifier
            .gap(35.px)
            .margin(topBottom = 20.px)
            .minHeight(40.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        SocialLinks()
    }

}

@Composable
private fun SocialLinks() {
    Link(
        path = "https://www.facebook.com/holudi",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {
        FaFacebook(
            modifier = SocialLinkStyle.toModifier(),
            size = IconSize.LG
        )
    }
    Link(
        path = "https://instagram.com/holudi_apps",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {
        FaInstagram(
            modifier = SocialLinkStyle.toModifier(),
            size = IconSize.LG
        )
    }
    Link(
        path = "https://twitter.com/holudi_apps",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {
        FaTwitter(
            modifier = SocialLinkStyle.toModifier(),
            size = IconSize.LG
        )
    }
}