package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.models.NavigationItem
import com.holudi.website.models.Routes
import com.holudi.website.models.navbarNavigationItems
import com.holudi.website.theme.LogoStyle
import com.holudi.website.theme.NavigationItemStyle
import com.holudi.website.theme.Theme
import com.holudi.website.util.Res
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.overlay.KeepPopupOpenStrategy
import com.varabyte.kobweb.silk.components.overlay.Popover
import com.varabyte.kobweb.silk.components.overlay.onHover
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun HoludiNavBar(onDarkBackground: Boolean = false, showContactButton: Boolean = true, onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier.fillMaxWidth()
            .height(65.px)
            .margin(topBottom = (if (breakpoint > Breakpoint.MD) 50.px else 30.px)),
        contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(modifier = Modifier.fillMaxSize().minWidth(250.px).backgroundColor(Colors.White))
            Box(modifier = Modifier.fillMaxSize())
        }
        Row(
            modifier = Modifier
                .fillMaxWidth(if (breakpoint > Breakpoint.MD) 80.percent else 100.percent)
                .fillMaxHeight()
                .gap(40.px),
            verticalAlignment = Alignment.CenterVertically
        ) {

            LeftSide(onDarkBackground, breakpoint, onMenuClicked)
            if (breakpoint > Breakpoint.MD) {
                RightSide(onDarkBackground, showContactButton)
            }
        }
    }
}

@Composable
fun LeftSide(onDarkBackground: Boolean, breakpoint: Breakpoint, onMenuClicked: () -> Unit) {
    Row(
        modifier = Modifier

            .height(65.px)
            .backgroundColor(if (onDarkBackground) Colors.White else Colors.Transparent),
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (breakpoint <= Breakpoint.MD) {
            FaBars(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .margin(left = 50.px, right = 30.px)
                    .onClick { onMenuClicked() },
                size = IconSize.XL
            )
        }

        Link(
            modifier = Modifier.height(30.px), // height needed otherwise image gets a padding and is not vertically centered anymore
            path = Routes.HOME,
        ) {
            Image(
                modifier = LogoStyle.toModifier().height(30.px),
                src = Res.Image.LOGO_LIGHT,
                desc = "Logo"
            )
        }
    }
}

@Composable
fun RightSide(onDarkBackground: Boolean, showContactButton: Boolean) {
    Row(
        modifier = Modifier
            .fillMaxSize()
            .gap(30.px)
            .borderRadius(topLeft = 0.px, bottomLeft = 0.px, topRight = 50.px, bottomRight = 50.px)
            .backgroundColor(if (onDarkBackground) Colors.White else Theme.Colors.BackgroundVariant.rgb)
            .padding(topBottom = 20.px, leftRight = 40.px),
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {

        navbarNavigationItems.forEach { item ->
            if (item.second.isNotEmpty()) {
                MenuPulldown(onDarkBackground, item.first, item.second)
            } else {
                MenuItem(item.first)
            }
        }

        if (showContactButton) {
            with(NavigationItem.Contact) {
                FilledSecondaryButton(path, longTitle)
            }
        }
    }
}

@Composable
fun MenuPulldown(onDarkBackground: Boolean, item: NavigationItem, dropdownItems: List<NavigationItem>) {
    DropDownParentMenuItem(item)
    Popover(
        ElementTarget.PreviousSibling,
//        Modifier.margin(top = 4.px),
        keepOpenStrategy = KeepPopupOpenStrategy.onHover(),
        hideDelayMs = 100,
    ) {
        Column(
            Modifier
                .padding(topBottom = 15.px, leftRight = 20.px)
                .gap(10.px)
                .backgroundColor(if (onDarkBackground) Colors.White else Theme.Colors.BackgroundVariant.rgb)
                .alignItems(AlignItems.Stretch)
        ) {
            dropdownItems.forEachIndexed { index, item ->
                DropdownMenuItem(item, index + 1)
            }
        }
    }
}

@Composable
fun MenuItem(item: NavigationItem) {
    Link(
        modifier = NavigationItemStyle.toModifier()
            .fontSize(18.px)
            .fontWeight(FontWeight.Normal)
            .textDecorationLine(TextDecorationLine.None),
        text = item.title,
        path = item.path
    )
}

@Composable
fun DropDownParentMenuItem(item: NavigationItem) {
    Div(
        NavigationItemStyle.toModifier()
            .fontSize(18.px)
            .fontWeight(FontWeight.Normal)
            .textDecorationLine(TextDecorationLine.None)
            .cursor(Cursor.Pointer)
            .tabIndex(0)
            .toAttrs(),
    ) {
        Text(item.title)
    }
}


@Composable
fun DropdownMenuItem(item: NavigationItem, tabIndex: Int) {
    Link(
        modifier = NavigationItemStyle.toModifier()
            .fontSize(18.px)
            .padding(topBottom = 5.px, leftRight = 5.px)
            .fontWeight(FontWeight.Normal)
            .textDecorationLine(TextDecorationLine.None)
            .tabIndex(tabIndex),
        text = item.title,
        path = item.path
    )
}