package com.holudi.website.pages

import androidx.compose.runtime.*
import com.holudi.website.components.sections.FooterSection
import com.holudi.website.components.sections.MainSection
import com.holudi.website.components.sections.AppPortfolioSection
import com.holudi.website.components.sections.ServicesOverviewSection
import com.holudi.website.components.widgets.OverflowMenu
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page

@Page
@Composable
fun HomePage() {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        var menuOpened by remember { mutableStateOf(false) }

        MainSection(onMenuClicked = { menuOpened = true })
        AppPortfolioSection()
//        ReferencesSection()
        ServicesOverviewSection()
        FooterSection()

        if (menuOpened) {

            OverflowMenu { menuOpened = false }

        }

    }
}
