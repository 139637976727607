package com.holudi.website.pages.apps

import androidx.compose.runtime.Composable
import com.holudi.website.components.layouts.GenericPageLayout
import com.holudi.website.components.sections.AppMainSection
import com.holudi.website.models.AppPortfolioItem
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint

@Page(routeOverride = "welkin-weather")
@Composable
fun WelkinWeather() {
    val breakpoint = rememberBreakpoint()
    GenericPageLayout("Welkin Weather", content = {
        AppMainSection(breakpoint, AppPortfolioItem.WelkinWeather)
    })
}
