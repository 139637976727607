package com.holudi.website.util

object Res {
    object Image {
        const val LOGO_LIGHT = "/images/holudi_purple.svg"
        const val HOME_BACKGROUND = "/images/bg_export.svg"
        const val PHONES_APP_SCREENS = "images/phones.png"
        const val LAPTOP_ZOOMED: String = "/images/luca-bravo-XJXWbfSo2f0-unsplash.jpg"
        const val PHONE_ON_TABLE: String = "/images/adrien-K12SrkaZuCg-unsplash.jpg"
        const val QUEST: String = "/images/remy-gieling-Zf0mPf4lG-U-unsplash.jpg"
        const val LAPTOP_WITH_TABLET: String = "/images/waldemar-brandt-UZQ7owGq3BM-unsplash-1024x683.jpg"
        const val SORT_RELAX: String = "/images/sort-relax-playstore.png"
        const val WELKIN_WEATHER: String = "/images/welkin-weather-playstore.png"
        const val PLAY_STORE_BADGE: String = "/images/google-play-badge.png"
        const val APP_STORE_BADGE: String = "/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
        const val WELKIN_WEATHER_SCREENSHOT_BEZEL: String = "/images/Screenshot_20230901_152107_WelkinWeather_bezel.png"
        const val SORT_RELAX_SCREENSHOT_BEZEL: String = "/images/Screenshot_SortRelax_Level5_Fruits_Bezel.png"
        const val WEBSITE: String = "/images/Website.png"
        const val WELKIN_WEATHER_FEATURE_GRAPHIC: String = "/images/Welkin_Weather_feature_graphic.png"
    }

    object Icon {
        const val ANDROID: String = "/icons/android_FILL0_wght400_GRAD0_opsz24.svg"
        const val CYCLE: String = "/icons/cycle_FILL0_wght400_GRAD0_opsz24.svg"
        const val FLASK: String = "/icons/flask-outline.svg"
        const val OPEN_IN_NEW: String = "/icons/open_in_new.svg"
    }
}