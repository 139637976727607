package com.holudi.website.theme

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent

val PortfolioCardOverlayStyle by ComponentStyle {

    cssRule(" > #overlayWrapper > #imageOverlay") {
        Modifier.fillMaxHeight(0.percent)
            .transition(CSSTransition(property = "height", duration = 400.ms))
    }

    cssRule(":hover > #overlayWrapper > #imageOverlay") {
        Modifier.fillMaxHeight(100.percent)
    }

    cssRule(" > #overlayWrapper > #imageOverlay > #learnMoreText") {
        Modifier.visibility(Visibility.Hidden)
    }

    cssRule(":hover > #overlayWrapper > #imageOverlay > #learnMoreText") {
        Modifier.visibility(Visibility.Visible)
    }
}
