package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.models.AppPortfolioItem
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px

@Composable
fun AppScreenshot(appPortfolioItem: AppPortfolioItem) {
    Image(
        modifier = Modifier.maxWidth(300.px)
            .objectFit(ObjectFit.Cover),
        src = appPortfolioItem.appScreenshot,
        desc = "Welkin Weather Screenshot"
    )
}