package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.px

@Composable
fun ButtonText(text: String) {
    SpanText(
        text = text, modifier = Modifier
            .fontWeight(FontWeight.Bold)
            .fontSize(16.px)
            .letterSpacing(.075.em)
            .whiteSpace(WhiteSpace.NoWrap)
    )
}