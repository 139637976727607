package com.holudi.website.theme


import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

@OptIn(ExperimentalComposeWebApi::class)
private val buttonModifier = Modifier
    .padding(leftRight = 24.px)
    .height(40.px)
    .border(width = 0.px)
    .borderRadius(r = 20.px)
    .styleModifier {
        filter {
            dropShadow(0.px, 0.px, 0.px, CustomColors.PurpleLight)
        }
    }
    .cursor(Cursor.Pointer)

@OptIn(ExperimentalComposeWebApi::class)
private val buttonDropShadowModifier =
    Modifier.styleModifier {
        filter {
            dropShadow(0.px, 2.px, 2.px, CustomColors.PurpleLight)
        }
    }

@OptIn(ExperimentalComposeWebApi::class)
private val secondaryButtonDropShadowModifier =
    Modifier.styleModifier {
        filter {
            dropShadow(0.px, 2.px, 2.px, CustomColors.YellowLight)
        }
    }

val FilledButtonStyle by ComponentStyle {
    base {
        buttonModifier
            .backgroundColor(Theme.Colors.Primary.rgb)
            .color(Theme.Colors.OnPrimary.rgb)
            .transition(CSSTransition(property = "padding", duration = 100.ms))
            .transition(CSSTransition(property = "filter", duration = 100.ms))
    }

    hover {
        buttonDropShadowModifier
    }
}

val FilledSecondaryButtonStyle by ComponentStyle {
    base {
        buttonModifier
            .backgroundColor(Theme.Colors.Secondary.rgb)
//            .color(Colors.Black)
            .color(Theme.Colors.OnBackground.rgb)
            .transition(CSSTransition(property = "padding", duration = 100.ms))
            .transition(CSSTransition(property = "filter", duration = 100.ms))
    }
    hover {
        secondaryButtonDropShadowModifier
    }
}


val OutlinedButtonStyle by ComponentStyle {
    base {
        buttonModifier
            .color(Theme.Colors.Primary.rgb)
//            .backgroundColor(Theme.Colors.Background.rgb)
            .backgroundColor(Colors.Transparent)
            .border(1.px)
            .border {
                style(LineStyle.Solid)
                color(Theme.Colors.Primary.rgb)
            }
            .transition(CSSTransition(property = "filter", duration = 100.ms))
    }

    hover {
        Modifier.backgroundColor(CustomColors.Purple10)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val GreyscaleImageStyle by ComponentStyle {
    base {
        Modifier.styleModifier {
            filter {
                grayscale(100.percent)
            }
        }
            .transition(CSSTransition(property = "filter", duration = 200.ms))
    }

    hover {
        Modifier.styleModifier {
            filter { grayscale(0.percent) }
        }

    }

}