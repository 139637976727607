package com.holudi.website.components.sections

import CustomColors
import androidx.compose.runtime.Composable
import com.holudi.website.components.widgets.FilledButton
import com.holudi.website.components.widgets.GrayscaleHeroImage
import com.holudi.website.components.widgets.OutlinedButton
import com.holudi.website.models.NavigationItem
import com.holudi.website.theme.LogoStyle
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.holudi.website.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun CompanyHeroSection(breakpoint: Breakpoint, secondaryButtonTarget: NavigationItem) {

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier
//            .id("...") // anchor
                .fillMaxWidth()
                .maxWidth(Constants.MAX_WIDTH.px)
                .backgroundColor(CustomColors.Purple25),
            contentAlignment = Alignment.Center
        ) {


            Column(
                modifier = Modifier
                    .fillMaxWidth(
                        if (breakpoint >= Breakpoint.MD) 90.percent
                        else 80.percent
                    )
                    .padding(topBottom = 50.px),
                horizontalAlignment = Alignment.CenterHorizontally

            ) {
                if (breakpoint >= Breakpoint.MD) {
                    Row(
                        modifier = Modifier.gap(25.px),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        HeroText(breakpoint, secondaryButtonTarget)
                        GrayscaleHeroImage(Res.Image.LAPTOP_ZOOMED)
                    }
                } else {
                    Column(modifier = Modifier.fillMaxWidth().gap(35.px)) {
                        HeroText(breakpoint, secondaryButtonTarget)
                        GrayscaleHeroImage(Res.Image.LAPTOP_ZOOMED)
                    }
                }
            }
        }
    }
}

@Composable
private fun HeroText(breakpoint: Breakpoint, secondaryButtonTarget: NavigationItem) {

    Column(modifier = Modifier.fillMaxWidth()) {
//        P(
//            attrs = Modifier
//                .margin(topBottom = 0.px)
//                .fontSize(if (breakpoint >= Breakpoint.LG) 50.px else 40.px)
//                .fontWeight(FontWeight.Bold)
//                .color(Theme.Colors.OnBackground.rgb)
//                .toAttrs()
//        ) {
//            Text("holudi Apps")
//        }
//        P(
//            attrs = Modifier
//                .margin(top = 15.px, bottom = 30.px)
//                .maxWidth(400.px)
//                .fontSize(18.px)
//                .fontWeight(FontWeight.Normal)
//                .color(Theme.Colors.OnBackground.rgb)
//                .toAttrs()
//        ) {
//            Text("Achieving speed through quality")
//        }
        Image(
            modifier = LogoStyle.toModifier().height(if (breakpoint >= Breakpoint.LG) 50.px else 40.px),
            src = Res.Image.LOGO_LIGHT,
            desc = "Logo"
        )
        P(
            attrs = Modifier
                .margin(top = 25.px, bottom = 0.px)
                .maxWidth(400.px)
                .fontSize(20.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text("Your success story begins with us, empowering your business every step of the way.")
        }

        Row(modifier = Modifier.gap(20.px).margin(top = 30.px)) {
            with(NavigationItem.Contact) {
                FilledButton(path = path, text = longTitle)
            }
            with(secondaryButtonTarget) {
                OutlinedButton(path = path, text = longTitle)
            }
        }
    }
}
