package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.theme.FilledButtonStyle
import com.holudi.website.theme.FilledSecondaryButtonStyle
import com.holudi.website.theme.OutlinedButtonStyle
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button

@Composable
fun FilledIconButton(
    path: String,
    text: String,
    icon: @Composable () -> Unit
) {
    Link(path = path) {
        Button(
            attrs = FilledButtonStyle.toModifier()
                .toAttrs()
        ) {
            Row(modifier = Modifier.gap(20.px), verticalAlignment = Alignment.CenterVertically) {
                icon()
                ButtonText(text)
            }
        }
    }
}

@Composable
fun FilledButton(
    path: String,
    text: String,
) {
    Link(path = path) {
        Button(
            attrs = FilledButtonStyle.toModifier()
//                .margin(top = 25.px, bottom = 0.px)
                .toAttrs()
        ) {
            ButtonText(text)
        }
    }
}

@Composable
fun FilledSecondaryButton(
    path: String,
    text: String,
) {
    Link(path = path) {
        Button(
            attrs = FilledSecondaryButtonStyle.toModifier()
//                .margin(top = 25.px, bottom = 0.px)
                .toAttrs()
        ) {
            ButtonText(text)
        }
    }
}


@Composable
fun OutlinedButton(
    path: String,
    text: String,
) {
    Link(path = path) {
        Button(
            attrs = OutlinedButtonStyle.toModifier()
//                .margin(top = 25.px, bottom = 0.px)
                .toAttrs()
        ) {
            ButtonText(text)
        }
    }
}
