package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun HeroSectionTitle(
    modifier: Modifier = Modifier,
    titleText: String,
    subtitleText: String,
    alignment: Alignment.Horizontal = Alignment.Start
) {

    Column(modifier, horizontalAlignment = alignment) {
        P(
            attrs = Modifier
                .fillMaxWidth()
                .textAlign(
                    if (alignment == Alignment.CenterHorizontally) TextAlign.Center
                    else TextAlign.Start
                )
                .margin(topBottom = 0.px)
                .fontSize(20.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.Primary.rgb)
                .toAttrs()
        ) {
            Text(titleText)
        }

        P(
            attrs = Modifier
                .fillMaxWidth()
                .textAlign(
                    if (alignment == Alignment.CenterHorizontally) TextAlign.Center
                    else TextAlign.Start
                )
                .margin(top = 5.px, bottom = 10.px)
                .fontSize(32.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text(subtitleText)
        }

        DecoLine()
    }
}

