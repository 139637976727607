package com.holudi.website

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerBaseStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

val BLOCK_MARGIN = Modifier.margin(top = 1.cssRem)
private val HEADER_MARGIN = Modifier.margin(top = 2.cssRem)

private val TEXT_FONT = Modifier.fontFamily("Wix").fontSize(18.px)
private val CODE_FONT = Modifier.fontFamily("Ubuntu Mono", "Roboto Mono", "Lucida Console", "Courier New", "monospace")

@InitSilk
fun updateTheme(ctx: InitSilkContext) {
    // Configure silk here
    ctx.apply {
        stylesheet.apply {
            registerBaseStyle("html") {
                // Always show a vertical scroller, or else our page content shifts when switching from one page that
                // can scroll to one that can't
                Modifier
                    .scrollBehavior(ScrollBehavior.Smooth)
                    .overflow { y(Overflow.Scroll) }
            }
            registerBaseStyle("body") { TEXT_FONT.lineHeight(1.5) }
            registerBaseStyle("code") { CODE_FONT }
            registerBaseStyle("canvas") { BLOCK_MARGIN }

            registerBaseStyle("p") { BLOCK_MARGIN }
            registerBaseStyle("pre") { BLOCK_MARGIN }
            registerBaseStyle("h1") {
                HEADER_MARGIN
                    .fontSize(2.5.cssRem)
                    .letterSpacing((-1.5).px)
                    .lineHeight(1.1)
            }
            registerBaseStyle("h2") { HEADER_MARGIN.fontSize(2.cssRem) }
            registerBaseStyle("h3") { HEADER_MARGIN.fontSize(1.5.cssRem) }
            registerBaseStyle("h4") { HEADER_MARGIN.fontSize(1.25.cssRem) }
        }

    }
}

@App
@Composable
fun MyApp(content: @Composable () -> Unit) {
    SilkApp {
        Surface(SmoothColorStyle.toModifier().minHeight(100.vh)) {
            content()
        }
    }
}