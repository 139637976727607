package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.models.AppPortfolioItem
import com.varabyte.kobweb.compose.css.VerticalAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.ResponsiveValues
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun StoreBadges(appPortfolioItem: AppPortfolioItem, numColumns: ResponsiveValues<Int> = numColumns(base = 1, md = 2)) {
    SimpleGrid(
        modifier = Modifier.gap(0.px).margin(left = 20.px, right = 20.px, bottom = 10.px, top = 10.px)
            .verticalAlign(VerticalAlign.Middle),
        numColumns = numColumns
    ) {
        appPortfolioItem.appStoreLink?.let {
            AppStoreBadge(it)
        }

        appPortfolioItem.playStoreLink?.let {
            PlayStoreBadge(it)
        }

        if (appPortfolioItem.appStoreLink == null) {
            Div(attrs = Modifier.width(140.px).height(60.px).toAttrs())
        }
    }
}