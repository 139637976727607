package com.holudi.website.components.widgets

import androidx.compose.runtime.Composable
import com.holudi.website.models.AppPortfolioItem
import com.holudi.website.theme.Theme
import com.holudi.website.util.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun AppPageMainDescription(appPortfolioItem: AppPortfolioItem) {
    Column(modifier = Modifier.maxWidth(400.px)) {
        Image(
            modifier = Modifier.maxSize(100.px).borderRadius(25.px).objectFit(ObjectFit.Cover),
            src = appPortfolioItem.appIcon,
            desc = appPortfolioItem.title
        )
        P(
            attrs = Modifier
                .margin(top = 25.px, bottom = 10.px)
                .fillMaxWidth()
                .fontSize(25.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text(appPortfolioItem.title)
        }
        P(
            attrs = Modifier
                .margin(bottom = 20.px)
                .fontSize(16.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Colors.OnBackground.rgb)
                .toAttrs()
        ) {
            Text(appPortfolioItem.description)
        }


        StoreBadges(appPortfolioItem)
    }
}