package com.holudi.website.components.sections

import androidx.compose.runtime.Composable
import com.holudi.website.components.widgets.AppPageMainDescription
import com.holudi.website.components.widgets.AppScreenshot
import com.holudi.website.models.AppPortfolioItem
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.px

@Composable
fun AppMainSection(breakpoint: Breakpoint, appPortfolioItem: AppPortfolioItem) {
    Column(modifier = Modifier.fillMaxWidth()) {

        if (breakpoint >= Breakpoint.MD) {
            Row(modifier = Modifier.gap(50.px), verticalAlignment = Alignment.CenterVertically) {
                AppScreenshot(appPortfolioItem)
                AppPageMainDescription(appPortfolioItem)
            }
        } else {
            Column(modifier = Modifier.gap(50.px), horizontalAlignment = Alignment.CenterHorizontally) {
                AppScreenshot(appPortfolioItem)
                AppPageMainDescription(appPortfolioItem)
            }
        }
    }
}
